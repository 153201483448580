import NavBar from "./components/NavBar";
import Home from "./components/Home"
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import ReactGA from 'react-ga'

function App() {
  ReactGA.initialize('G-M6787H1RSL');
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <NavBar />
      <Home />
      <About />
      <Skills />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
