import React from 'react'

const Contact = () => {
  return (
    <div name='contact' className='bg-[#586D80] w-full h-screen flex justify-center items-center p-4'>
        <form method='POST' action="https://getform.io/f/17c6c2ce-30fe-4875-b92c-1e9a4f1a5bf2" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-[#B95756] text-gray-300'>Contact Me</p>
                <p className='text-gray-300 py-4'>// Submit the form or write me an Email: info@korobeiniki.me</p>
            </div>
            {/* input area */}
                <input className='bg-[#C4C1BC] p-2'
                type="text" placeholder='Name' name='name' />

                <input className='my-4 p-2 bg-[#C4C1BC]' 
                type="email" placeholder='Email' name='email' />

                <textarea className='bg-[#C4C1BC] p-2'
                name="message" rows="10" placeholder='Message'></textarea>

                <button className='text-white border-2 transition duration-500 ease-in-out hover:bg-[#CC5F5A] hover:border-[#CC5F5A]
                py-3 px-4 my-8 mx-auto flex items-center
                '>Say Hi to Me!</button>
        </form>
    </div>
  )
}

export default Contact