import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#586D80] text-gray-300'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full px-4 grid grid-cols-2 gap-8'>
                {/* align the title on the right when the screen is mobile */}
                <div className='sm:text-right pb-8 pl-4'>
                    <p className='text-4xl font-bold inline border-b-4 border-[#CC5F5A]'>About</p>
                </div>
            </div>
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                <div className='sm:text-right text-4xl font-bold'>
                    <p>Nice To Meet You Again, Please take a look around</p>
                </div>
                <div>
                    <p>Experienced Journalist, especially good at marketing project management and stakeholder communication. 
                        Able to compose the digital content on various platforms. 
                        Still learning any knowledge I can get about Web Design.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About