import React from 'react'
import projectimg from '../assets/project1.png'
import blog from '../assets/project2.png'
import tests from '../assets/project3.png'
import yoga from '../assets/project4.png'


const Projects = () => {
  return (
    <div name='projects' className='w-full md:h-screen bg-[#586D80] text-gray-300'>
        {/* container */}
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-[#CC5F5A]'>Projects</p>
                <p className='py-6'>//Check out some of my recent ongoing/finished projects</p>
            </div>

            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
                {/* Grid Items */}
                <div style={{backgroundImage: `url(${projectimg})`}} 
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center
                items-center mx-auto content-div'>
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                            Personal Website
                        </span>
                    </div>
                </div>
                {/* Grid Items */}
                <div style={{backgroundImage: `url(${blog})`}} 
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center
                items-center mx-auto content-div'>
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                        Korobeiniki's Blog
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://korobeinikihansblog.app/">
                                <button className='text-center rounded-lg px-4 py-3 m-2 
                                bg-white text-gray-700 font-bold text-lg'>Visit Site</button>
                            </a>
                        </div>
                    </div>
                </div>
                {/* Grid Items */}
                <div style={{backgroundImage: `url(${tests})`}} 
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center
                items-center mx-auto content-div'>
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                        TAS Test 
                        </span>
                        <div className='pt-8 text-center'>
                            {/* <a href="https://korobeinikihansblog.app/">
                                <button className='text-center rounded-lg px-4 py-3 m-2 
                                bg-white text-gray-700 font-bold text-lg'>Visit Site</button>
                            </a> */}
                        </div>
                    </div>
                </div>
                {/* Grid Items */}
                <div style={{backgroundImage: `url(${yoga})`}} 
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center
                items-center mx-auto content-div'>
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                        Flowers & Pilates
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="http://flowersandpilates.com.au//">
                                <button className='text-center rounded-lg px-4 py-3 m-2 
                                bg-white text-gray-700 font-bold text-lg'>Visit Site</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Projects