import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import {Link} from 'react-scroll'

const Home = () => {

  return (
    <div name='home' className='bg-[#586D80] w-full h-screen'>

        {/* container*/}
        <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
            <p className='text-[#B95756]'>Hi, My Name Is</p>
            <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>Korobeiniki</h1>
            <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>A graduated Front-End Developer</h2>
            <p className='text-[#8892b0] py-4 max-w-[700px]'>Journalist background Geek. Try my best to be a Technical Savvy. 
                Eager learning Web Development, Graphic Design and SEO.</p>
                <div>
                <Link to="projects" smooth={true}  duration={500}>
                    <button className='text-white border-2 px-6 py-3 my-2 flex items-center 
                    hover:transition duration-500 ease-in-out hover:bg-[#B95756] hover:border-[#B95756]'>
                        View My Works
                        <span className='group-hover:rotate-90 duration-300'>
                            <HiArrowNarrowRight className='ml-3'/>
                        </span>    
                    </button>
                </Link>       
                </div>
        </div>
    </div>
  )
}

export default Home