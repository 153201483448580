import React from 'react'
import HTML from '../assets/html.png'
import JavaScript from '../assets/javascript.png'
import reactImg from '../assets/react.png'
import vue from '../assets/VUE.png'
import css from '../assets/css.png'
import sass from '../assets/sass.png'
import wordpress from '../assets/WordPress.png'
import Tailwind from '../assets/tailwind.png'

const Skills = () => {
  return (
    <div name='skills' className='bg-[#586D80] text-gray-300 w-full h-screen'>
      {/* Container */}
      <div className='max-w-[1000px] p-4 mx-auto flex flex-col justify-center w-full h-full'>
        <div>
          {/* title and banner */}
          <p className='text-4xl font-bold border-b-4 border-[#CC5F5A]'>Skills</p>
          <p className='py-4'>//These is my Skill Set (..keep updating as I'm working)</p>
        </div>
          {/* Skill sets icons */}
        <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <img src={HTML} alt="html icon" className='w-20 mx-auto my-4'/>
            <p className='py-4'>HTML5</p>
          </div>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <img src={sass} alt="html icon" className='w-20 mx-auto my-4'/>
            <p className='py-4'>SASS</p>
          </div>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <img src={css} alt="html icon" className='w-20 mx-auto my-4'/>
            <p className='py-4'>CSS3</p>
          </div>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <img src={JavaScript} alt="html icon" className='w-20 mx-auto my-4'/>
            <p className='py-4'>JavaScript(ES6)</p>
          </div>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <img src={reactImg} alt="html icon" className='w-20 mx-auto my-4'/>
            <p className='py-4'>ReactJS</p>
          </div>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <img src={vue} alt="html icon" className='w-20 mx-auto my-4'/>
            <p className='py-4'>Vue.js</p>
          </div>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <img src={wordpress} alt="html icon" className='w-20 mx-auto my-4'/>
            <p className='py-4'>WordPress Development</p>
          </div>
          <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
            <img src={Tailwind} alt="html icon" className='w-20 mx-auto my-4'/>
            <p className='py-4'>Tailwind</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills